import React from 'react';
import './App.css';
import {
  DateInput
} from 'semantic-ui-calendar-react';
import {Form} from 'semantic-ui-react'
import axios from 'axios'

class App extends React.Component {
  state = {
    interval: 3,
    data: [],
    status: 'not requested',
    refresh: 0,
    int: null,
    date: '',
    minuteInt: 5,
    display: null

  }
  componentDidMount() {
    this.call()
    let display = []
    for(let i = 0; i < 24; i ++) {
      let title = i
      display.push({title: title})
      this.setState({display: display });
    }
  }

  handleChange = (event, {name, value}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }
  call = () => {
    let that = this
    that.setState({status: 'requesting', refresh: that.state.interval})
    console.log(this.state.date)
    axios.get(`http://89.212.216.60:3000/api/users/` + this.state.date )
    .then(res => {
      const data = res.data;
      that.setState({data: data, status: 'data set'})
      clearInterval(that.state.int)
      let newInterval =  setTimeout(function() { that.call()}, 1000 * that.state.interval )
      that.setState({int: newInterval})

      let newRefresh = 0
      that.setState({status: 'success', int:  setInterval(function() {
          
        if(that.state.refresh === 0) {
          newRefresh = 0
        } else {
          newRefresh = that.state.refresh -1
        }
        that.setState({refresh: newRefresh})
      }, 1000)})
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  }
  render() {
    return <div>
      
      <Form>
        <DateInput
          name="date"
          placeholder="Date"
          value={this.state.date}
          iconPosition="left"
          onChange={this.handleChange}
          autoComplete="off"
        />
      </Form>
      
      
      <table>
        <tbody>
  {Object.keys(this.state.data).map((keyName, i) =><tr key={i}><td>{this.state.data[keyName][this.state.data[keyName].length-1].user_name ? this.state.data[keyName][this.state.data[keyName].length-1].user_name : keyName}</td><td>{this.state.data[keyName][this.state.data[keyName].length-1].date}</td><td>{this.state.display.map((e, a) => {let hours = this.state.data[keyName].map((e) => new Date(e.data_time).getHours()); return  hours.indexOf(e.title) > -1 ? <span className="active" key={a}>{e.title}</span> : <span key={a}>{e.title}</span>} )}</td></tr>)}
        </tbody>
      </table>
      <div>{this.state.status}({this.state.refresh})</div>
      </div>
      
    
  }
}

export default App;
